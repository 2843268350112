.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 32px 20px 32px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 576px) {
  .headerContainer {
    padding: 20px 16px 20px 16px;
    .children {
      display: none;
    }
    .title {
      font-size: 18px;
    }
  }
 
}
