.menu {
    &_wrapper {
        [class~='ant-menu-item'] {
            [class~='ant-menu-title-content']
             {
                color: #212426;
                font-weight: 500;
            }
        }

        [class~='ant-menu-item-selected'] {
            [class~='ant-menu-title-content']
             {
                color: #0097A9;
                font-weight: 500;
            }
        }
    }
}