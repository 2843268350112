.wrapper {

  &_menu {
    border-radius: 8px;
  }
  &_bodyModal {

    [class~='ant-modal-content'] {
      padding: 16px;
    }
    
    
  }
  &_header {
    &_title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }

    &_desc {
      margin-top: 16px;
      display: block;
    }
  }

  &_footer {
    margin-top: 24px;
    button {
      width: 100%;
    }

    &_cancel {
      background-color: #F5F7FA;
      span {
        color: #848484;
      }
    }

    &_submit {
      background-color: #FF8060;
      span {
        color: #ffffff;
      }
    }
  }
}
