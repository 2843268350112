.popoverContainer{
    padding: 0 !important;
    max-height: 300px !important;
    [class~='ant-popover-inner']{
        padding: 0 !important;
    }
    [class~='ant-popover-title']{
        padding: 12px !important;
    }
    .btnSeeAll{
        span{
        color: #0097A9 !important;
        }
        border: none !important;
    }
}

.texTitle{
    font-weight: 700 !important;
    
}
.textMarkAll{
    font-size: 14px !important;
    color: #0097A9 !important;
    font-weight: 400 !important;
    cursor: pointer;
}

.container {
  min-height: 400px;
  overflow: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  * {
    color: #212426;
  }
  [class~='ant-list-item'] {
    padding: 12px !important;
    border-block-end: none !important;
  }

  .time {
    font-size: 12px !important;
    color: #848484;
  }
  .dot {
    height: 8px;
    width: 8px;
    background-color: #ff8060;
    border-radius: 50%;
    display: inline-block;
  }
}

.drawer {
  [class~='ant-drawer-body'] {
    padding: 0 !important;
  }
}

.modalNotify {
  font-family: DM Sans, sans-serif ;
  &_textTitle {
    font-size: 16px;
    font-weight: 700;
    color: #212426;
  }
  padding: 0 !important;
  [class~='ant-modal-content'] {
    padding: 0 !important;
  }
  [class~='ant-modal-title'] {
    padding: 13.5px 16px !important;
    border-bottom: 1px solid #f0f3fa !important;
  }
  [class~='ant-modal-close-x'] {
    margin-bottom: 5px !important;
  }
  [class~='ant-modal-header'] {
    margin-bottom: 0 !important;
  }
}
